import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`All gym members and their families are invited to our annual
Christmas party tonight at 5:00pm at the Barnett home.  We will have a
chili contest with a trophy up for grabs! If your last name begins with
A-M please bring a side dish, N-Z please bring a dessert. `}<em parentName="strong">{`Please RSVP
to let us know if you will be bringing chili and how many are attending
and we’ll send you the address. RSVP to:
Daniel\\@crossfitlouisvilleeast.com`}</em></strong></p>
    <p><em parentName="p">{`Last Day of Test Week!`}</em></p>
    <p>{`Front Squat 1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`“Hollywood”`}</p>
    <p>{`2KM Run (1.24 miles)`}</p>
    <p>{`22 Wall Balls (30/20`}{`#`}{`)`}</p>
    <p>{`22 Muscle Ups`}</p>
    <p>{`22 Wall Balls`}</p>
    <p>{`22 Power Cleans (185/125)`}</p>
    <p>{`22 Wall Balls`}</p>
    <p>{`2KM Run`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit Kids today at 9:30am at Louisville East.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      